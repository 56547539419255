import { render, staticRenderFns } from "./PropertyPickupInput.vue?vue&type=template&id=415c0ca7&lang=pug"
import script from "./PropertyPickupInput.vue?vue&type=script&lang=ts"
export * from "./PropertyPickupInput.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SelRefined: require('/app/components/vantone/SelRefined.vue').default,TextInputError: require('/app/components/generic/forms/TextInputError.vue').default})
