
import Vue from "vue"
import "swiper/swiper-bundle.min.css"
import { Swiper } from "swiper/bundle"

export default Vue.extend({
  name: "CarouselSwiper",
  props: {
    block: {
      required: true,
      type: Object as () => Block
    },
    flushImage: {
      type: Boolean
    },
    lazy: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      carouselSwiper: {} as Swiper,
      baseParams: {
        loop: true,
        slidesPerView: 1.08,
        spaceBetween: 24,
        breakpoints: {
          480: {
            slidesPerView: 1.5,
            spaceBetween: 24
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 30
          },
          992: {
            slidesPerView: 3,
            spaceBetween: 30
          },
          1200: {
            slidesPerView: 4,
            spaceBetween: 30
          }
        },
        pagination: {
          el: ".swiper-pagination",
          type: "bullets"
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        },
        on: {
          init: () => {
            this.$nextTick(() => {
              // make slider visible after it's initialized to avoid flicker
              const swiperWrapperEl = document.querySelector(".carousel-swiper")
              if (swiperWrapperEl) {
                ;(swiperWrapperEl as HTMLElement).style.height = "auto"
              }
            })
          }
        }
      } as Record<string, any>
    }
  },

  mounted() {
    console.log("flushImage: ", typeof this.flushImage, this.flushImage)
    this.carouselSwiper = new Swiper(".swiper", this.baseParams)
  }
})
