// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.avMediaText_gp1Bx {\ndisplay: flex;\nflex-direction: column;\ngap: 32px;\n}\n@media (min-width: 992px) {\n.avMediaText_gp1Bx {\ndisplay: grid;\nalign-items: center;\ngrid-column-gap: 64px;\n-moz-column-gap: 64px;\n     column-gap: 64px;\n    grid-template-columns: 1fr 1fr;\n}\n.avMediaText_gp1Bx.imageOnRight .mediaTextContainer_hI89o {\n        order: -1;\n}\n}\n.mediaTextContainer_hI89o .av-buttons {\nmargin-top: 32px;\n}\n.mediaTextContainer_hI89o h2.av-heading {\nmargin-bottom: 32px;\n}\n.mediaTextContainer_hI89o h3.av-heading,\n    .mediaTextContainer_hI89o h4.av-heading,\n    .mediaTextContainer_hI89o h5.av-heading,\n    .mediaTextContainer_hI89o h6.av-heading {\nmargin-bottom: 0.25em;\nfont-size: 1.5rem;\n}\n.mediaTextContainer_hI89o p {\nmargin-bottom: 1em;\nfont-size: 1.25rem;\n}\n.mediaTextContainer_hI89o p + .av-heading {\nmargin-top: 32px;\n}\n.mediaTextContainer_hI89o h4.av-heading + p {\ncolor: rgba(0, 0, 0, 0.5);\n}\n.mediaTextContainer_hI89o ul {\nmargin-left: 8px;\nlist-style-type: disc;\npadding-left: 16px;\n}\n@media (min-width: 992px) {\n.mediaTextContainer_hI89o ul {\nmargin-left: 0;\npadding-left: 24px;\n}\n}\n.mediaTextContainer_hI89o ul li {\nmargin-bottom: 0.75em;\nfont-size: 1rem;\nline-height: 1.2;\n}\n@media (min-width: 576px) {\n.mediaTextContainer_hI89o ul li {\nfont-size: 1.25rem;\n}\n}\n.highlight {\n--tw-text-opacity: 1;\ncolor: rgba(65, 165, 221, 1);\ncolor: rgba(65, 165, 221, var(--tw-text-opacity));\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"avMediaText": "avMediaText_gp1Bx",
	"mediaTextContainer": "mediaTextContainer_hI89o"
};
module.exports = ___CSS_LOADER_EXPORT___;
