
import { Component, Prop, mixins, Getter, Action, Watch } from "nuxt-property-decorator"
import { ValidationObserver } from "vee-validate"
import axios from "axios"
import Blocks from "~/mixins/blocks"
import { GET_LISTING } from "~/store/listing/getters"
import {
  ListingService,
  getV4Url,
  getV4ListingPayload,
  addPreListingIdToSessionStorage,
  isValidPropertyType
} from "~/services/public/Listing"
import { AVAILABILITY_REMARK, CALL_US, RETRIEVE_QUOTE, VALIDATION } from "~/constants/blocks"
import { redirectToV2 } from "~/utils/v4-redirect"
import { GET_PHONE_NUMBER_VALUE } from "~/store/phone-number/getters"
import eventBus from "~/scripts/bus"
import { SET_DELIVERY_PROPERTY_TYPE, SET_PICKUP_PROPERTY_TYPE } from "~/store/listing/actions"
import { extractHref, getInnerText, getv4LocaleFromI18nLocale } from "~/scripts/useful-functions"
import { GET_WINDOW_WIDTH } from "~/store/pages/getters"
import {
  ADD_FLOORS,
  ADD_PROPERTY_TYPES,
  ADD_PROPERTY_TYPES_FLAT,
  ADD_PROPERTY_TYPES_HOUSE
} from "~/store/property/actions"
import { CATEGORY_V4_ID_HOUSE_MOVE } from "~/constants/category"

@Component({
  methods: { extractHref, getInnerText },
  components: {
    ValidationObserver
  }
})
export default class RemovalsLocationForm extends mixins(Blocks) {
  submittedForm: boolean = false
  submittingForm: boolean = false
  emailFormShow: boolean = false
  trustpilotCustomers = "0"
  $refs!: {
    [x: string]: any
    observer: InstanceType<typeof ValidationObserver>
  }

  @Prop({ type: String, default: "" }) initialPropertyType: string
  @Prop({ type: Boolean, default: false }) propertyTypeHidden: boolean
  @Prop({ type: Boolean, default: false }) propertyFloorHidden: boolean
  @Prop({ required: true, type: Object }) block: Record<string, any>

  @Getter(GET_WINDOW_WIDTH, { namespace: "pages" }) getWindowWidth: number
  @Getter(GET_LISTING, { namespace: "listing" }) getListing: Listing | null
  @Getter(GET_PHONE_NUMBER_VALUE, { namespace: "phone-number" })
  getPhoneNumberValue: PhoneNumberPair

  @Action(SET_PICKUP_PROPERTY_TYPE, { namespace: "listing" }) setPickupPropertyType: Function
  @Action(SET_DELIVERY_PROPERTY_TYPE, { namespace: "listing" }) setDeliveryPropertyType: Function
  @Action(ADD_FLOORS, { namespace: "property" }) addFloors: Function
  @Action(ADD_PROPERTY_TYPES, { namespace: "property" }) addPropertyTypes: Function
  @Action(ADD_PROPERTY_TYPES_HOUSE, { namespace: "property" }) addPropertyTypesHouse: Function
  @Action(ADD_PROPERTY_TYPES_FLAT, { namespace: "property" }) addPropertyTypesFlat: Function

  @Watch("submittingForm") onSubmittingFormChanges(val, oldVal) {
    if (val) {
      eventBus.$emit("submitting-location-form")
    } else {
      eventBus.$emit("submitted-location-form")
    }
  }

  get locationBlock() {
    return this.block.innerBlocks[0]
  }

  get propertyBlock() {
    return this.block.innerBlocks[this.block.innerBlocks.length - 2]
  }

  get ctaBlock() {
    return this.block.innerBlocks[this.block.innerBlocks.length - 1]
  }

  async created() {
    if (this.initialPropertyType) {
      this.setPickupPropertyType(this.initialPropertyType)
      this.setDeliveryPropertyType(this.initialPropertyType)
    }
    const trustPilotConfig = this.$config.trustPilot
    let trustPilotId = trustPilotConfig.id
    let trustPilotAPIKey = trustPilotConfig.apiKey
    if (this.$i18n.locale !== "en") {
      const upperCaseLocale = this.$i18n.locale.toUpperCase()
      trustPilotId = trustPilotConfig[`id${upperCaseLocale}`]
      trustPilotAPIKey = trustPilotConfig[`apiKey${upperCaseLocale}`]
    }
    const trustpilotURL = `https://api.trustpilot.com/v1/business-units/${trustPilotId}`
    try {
      const axiosInstance = axios.create() as any
      const response = await axiosInstance.get(trustpilotURL, {
        params: {
          apikey: trustPilotAPIKey
        }
      })
      if (response.status === 200 && response.data) {
        this.trustpilotCustomers = response.data.numberOfReviews.total
      }
    } catch (err) {
      console.log("Trustpilot error", err)
    }

    const itemsUrl = this.$config.v4Host + "/api/v4/booking-flow/config/house-move"
    try {
      const axiosInstance = axios.create() as any
      const headers = {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          "AV-locale": getv4LocaleFromI18nLocale(this.$i18n.locale)
        }
      }
      const response = await axiosInstance.get(itemsUrl, headers)
      if (response.status === 200 && response.data) {
        this.addPropertyTypes(response.data.propertyTypes)
        this.addFloors(response.data.propertyFloors.floors)
        const houseObject = response.data.propertyTypes.find(item => item.identifier === "house")
        this.addPropertyTypesHouse(houseObject.values)
        const flatObject = response.data.propertyTypes.find(item => item.identifier === "flat")
        this.addPropertyTypesFlat(flatObject.values)
      }
    } catch (err) {
      console.log("Property config error", err)
    }
  }

  mounted() {
    if (this.initialPropertyType) {
      this.setPickupPropertyType(this.initialPropertyType)
      this.setDeliveryPropertyType(this.initialPropertyType)
    }
  }

  beforeMount() {
    eventBus.$on("submit-location-form", this.submitForm)
  }

  beforeDestroy() {
    eventBus.$off("submit-location-form")
  }

  get hasAvailabilityRemark() {
    return this.Blocks_patternCheck(this.block.attributes, AVAILABILITY_REMARK)
  }

  get hasCallUs() {
    return this.Blocks_patternCheck(this.block.attributes, CALL_US)
  }

  get hasRetrieveQuote() {
    return this.Blocks_patternCheck(this.block.attributes, RETRIEVE_QUOTE)
  }

  scrollInputToTop(el) {
    if (this.getWindowWidth && this.getWindowWidth > 768) return
    // @ts-ignore
    let element
    switch (el) {
      case "pickup":
        element = this.$refs.pickupInput
        break
      case "delivery":
        element = this.$refs.deliveryInput
        break
      default:
        return
    }
    if (element && element.$el.offsetTop) {
      window.scrollTo({
        top: element.$el.offsetTop - 25,
        behavior: "smooth"
      })
    }
  }

  async submitForm() {
    if (this.submittingForm) return
    this.submittedForm = true
    this.submittingForm = true
    try {
      const listing = this.getListing
      if (!isValidPropertyType(listing?.pickup.propertyType)) {
        await this.setPickupPropertyType(null)
      }
      if (!isValidPropertyType(listing?.delivery.propertyType)) {
        await this.setDeliveryPropertyType(null)
      }
      const isValid = await this.$refs.observer.validate()
      if (!isValid) {
        this.submittingForm = false
        eventBus.$emit("close-dropdown")
        return
      }

      const categoryV4Id = CATEGORY_V4_ID_HOUSE_MOVE

      this.$snowplow.trackButtonClick(
        getInnerText(this.ctaBlock.innerBlocks[1].innerBlocks[0].innerBlocks[0].innerHtml),
        this.$config.v4Host + "/" + CATEGORY_V4_ID_HOUSE_MOVE
      )

      const payload = getV4ListingPayload(listing, categoryV4Id, this.$i18n.locale)

      // This condition redirects to auction if the locations are international
      // or one of the locations is on an island.
      if (payload.v === 2) {
        redirectToV2(payload, this.$config.v4Host)
        this.submittingForm = false
        return
      }

      const listingService = new ListingService(this.$axios, this.$config)
      const response: ListingResponse = await listingService.postPreListing(
        payload,
        this.$i18n.locale
      )

      if (response?.data && response.data?.pre_listing_id && response.data?.category_id) {
        if (response.is_international) {
          redirectToV2(payload, this.$config.v4Host)
          this.submittingForm = false
          return
        }

        addPreListingIdToSessionStorage(response.data)
        document.addEventListener("visibilitychange", () => {
          this.submittingForm = false
          this.submittedForm = false
        })

        window.location.href = getV4Url(
          this.$config.v4Host,
          response.data,
          payload,
          this.$i18n.locale
        )
        return
      } else {
        this.submittingForm = false
      }
    } catch (err) {
      this.submittingForm = false
      console.log("PreListing creation error", err)
    }
  }

  hasValidation(block) {
    return this.Blocks_patternCheck(block.attributes, VALIDATION)
  }

  onEmailFormOpen() {
    this.emailFormShow = true
  }

  onEmailFormClose() {
    this.emailFormShow = false
  }

  handleCallUsClick(label = "location_form_button") {
    this.$snowplow.trackCallUs({
      phoneNumber: this.getPhoneNumberValue.baseRaw,
      label,
      action: "click",
      extension: this.getPhoneNumberValue.extension
    })
  }
}
