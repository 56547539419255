import { render, staticRenderFns } from "./WrapperBlock.vue?vue&type=template&id=bd7439ca&lang=pug"
import script from "./WrapperBlock.vue?vue&type=script&lang=ts"
export * from "./WrapperBlock.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MediaText: require('/app/components/generic/MediaText.vue').default,FaqsOriginal: require('/app/components/generic/FaqsOriginal.vue').default,TrustpilotReviews: require('/app/components/generic/TrustpilotReviews.vue').default,PostTiles: require('/app/components/generic/PostTiles.vue').default,PageTileNavigation: require('/app/components/generic/PageTileNavigation.vue').default,AvBreadcrumbs: require('/app/components/generic/AvBreadcrumbs.vue').default,Breadcrumbs: require('/app/components/generic/Breadcrumbs.vue').default,Block: require('/app/components/generic/Block.vue').default})
